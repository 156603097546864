import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 8-8-8-8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-SDHP’s (95/65)`}</p>
    <p>{`9-Ring Muscle Ups`}</p>
    <p>{`15-SDHP’s`}</p>
    <p>{`7-Ring Muscle Ups`}</p>
    <p>{`9-SDHP’s`}</p>
    <p>{`5-Ring Muscle Ups`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Starting this week we will no longer have a 6:30pm class on Tuesday
& Thursday at East due to low attendance.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      